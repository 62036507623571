import styled from '@emotion/styled'

const ContentStyled = styled.div`
  max-width: 720px;
  padding: 16px;

  @media screen and (min-width: 56em) {
    margin-left: 400px;
  }
`

export default ContentStyled
