import React from 'react'

import Content from '../components/Content'
import Sidebar from '../components/Sidebar'

const Layout = ({ children }) => {
  return (
    <div>
      <Sidebar />
      <Content>{children}</Content>
    </div>
  )
}

export default Layout
