import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text, Link } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

const SidebarStyled = styled.div`
  height: 80px;
  padding-top: 8px;
  background-color: ${props => props.theme.colors.sidebarBackground};
  color: ${props => props.theme.colors.sidebarText};

  @media screen and (min-width: 56em) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 320px;
    height: unset;
    padding: 40px 20px;
  }
`

const Sidebar = () => (
  <SidebarStyled>
    <Flex sx={{ flexDirection: 'column', padding: '0 16px' }}>
      <Box pb={4}>
        <GatsbyLink to="/" style={{ textDecoration: 'none' }}>
          <Text color="modes.dark.text" sx={{ fontSize: 4 }}>
            Antonio Sandoval
          </Text>
        </GatsbyLink>
        <Text>Full stack developer</Text>
      </Box>
      <Link href="https://github.com/archr" color="modes.dark.text">
        github
      </Link>
      <Link href="https://github.com/__arch3r" color="modes.dark.text">
        twitter
      </Link>
    </Flex>
  </SidebarStyled>
)

export default Sidebar
